'use strict';
$(document).ready(function () {
    $("#sylius-product-adding-to-cart").on("submit", function (e) {
        e.preventDefault();
        var $form = $(this);
        var href = $($form).attr("action");
        var redirectUrl = $($form).data("redirect");
        var validationElement = $('#sylius-cart-validation-error');
        let form = $form[0];
        let data = new FormData(form);

        $.ajax({
            method: "POST",
            cache: false,
            url: href,
            data,
            processData: false,
            contentType: false,
        }).done(function () {
            validationElement.addClass("hidden");
            window.location.href = redirectUrl;
        }).fail(function (response) {
            response = response.responseJSON;
            if (response.errors !== undefined) {
                // let validationMessage = "Oh no ! 😱 😨";
                let validationMessage = Array.from(response.errors.errors).join("<br/>");
                validationElement.html(validationMessage);
                validationElement.removeClass("hidden");
                let formErrors = response.errors.form.children.cartItem.children;
                let elements = Array.from(form.elements).filter(element => element.type !== "button" && element.type !== "submit");
                let elementsMap = new Map();
                for (let element of elements) {
                    let m = element.name.match(/\[(\w|-)+]$/g);
                    if (m.length > 0) elementsMap.set(m[0], element);
                }
                for (let formErrorKey in formErrors) {
                    let element = elementsMap.get(`[${formErrorKey}]`);
                    let $element = $(element);
                    let $errorElement = $element.next();
                    if ($errorElement.length === 0) {
                        $errorElement = $(document.createElement("div"))
                            .addClass("ui red pointing label sylius-validation-error hidden");
                        $errorElement.insertAfter($element);
                    } else $errorElement = $($errorElement[0]);
                    if (formErrors[formErrorKey].errors !== undefined) {
                        $errorElement.html(Array.from(formErrors[formErrorKey].errors).join("<br/>"));
                        $errorElement.removeClass("hidden");
                    } else {
                        $errorElement.html("");
                        $errorElement.addClass("hidden");
                    }
                }
            } else {
                validationElement.html(response.message !== "" ? response.message : "Une erreur est sruvenue.");
                validationElement.removeClass("hidden");
            }
            $($form).removeClass("loading");
        });
    });
});